/* THE MAIN CSS CONTAINS ALL THE ELEMENTS */
/*

1 --- GLOBAL STYLES
2 --- NAVBAR
3 --- HERO SECTION
4 --- ABOUT SECTION
5 --- SERVICES SECTION
6 --- PORTFOLIO SECTION
7 --- TESTIMONIALS SECTION
8 --- CONTACT SECTION
9 --- FOOTER SECTION
10 --- MEDIA QUERIES

*/

/* 1 --- GLOBAL STYLES */
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400&family=Open+Sans:wght@300&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html{
  font-family: 'Poppins', sans-serif;
}

:root {
  --primary-color: #1E90FF;
  --secondary-color: #fff;
  --dark-color: #000;
  --light-color: #fff;

}
a {
  text-decoration: none;
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.App {
  /* text-align: center; */
}

.swiper{
  /* background: linear-gradient(51deg, rgba(0,0,163,1) 0%, rgba(0,103,179,1) 50%, rgba(64,176,223,1) 99%); */
  background-color: #242424;
}

.hero-slide {
  position: relative;
  height: 100vh;
  background-size: cover;
  background-position: center;
}

.hero-slide-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  max-width: 80%;
  margin: 0 auto;
}

.hero-slide-content h1 {
  font-size: 10rem;
  color: #fff;
  font-weight: 400;
  line-height: 1;
}

.hero-slide-content h1:hover {
  transform: scale(1.1);
  transition: all 0.5s ease-in-out;
}

.hero-slide-content h4 {font-size: 3rem;}


.hero-slide-content p {
  font-size: 1.5rem;
  /* margin-bottom: 2rem; */
  padding-top: 2rem;
}

.swiper-button-prev {
  color : #fff!important;
  margin-left: 5px!important;
}

.swiper-button-next{
  color : #fff!important;
  margin-right: 5px!important;
}

.swiper-pagination-bullet{background-color: #fff!important;}

/* Tablet size */

@media (max-width: 968px) {
  .hero-slide-content h1 {
    font-size: 5rem;
  }
  .hero-slide-content h4 {
    font-size: 2rem;
    margin-bottom: 2rem!important;

  }
  .hero-slide-content p {
    font-size: 1rem;
  }
}

/* Mobile size */

@media (max-width: 480px) {
  .hero-slide-content h1 {
    font-size: 3rem;
  }
  .hero-slide-content h4 {
    font-size: 1.5rem;
    margin-bottom: 2rem!important;
  }
  .hero-slide-content p {
    font-size: 1rem;
  }
}


/* 5 ------ Services Section */
.page-img{
  border-radius: 20px;
}

.home-about-section .about-img-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.home-about-section .about-img-left .about-img {
  margin-top: 100px;
  padding-bottom: 100px;
}
.home-about-section .about-img-right .about-img {
  border: 10px solid #ffffff;
}
.home-about-section .about-content {
  border: 5px solid #ffffff;
  margin: 40px 0 0 30px;
  padding: 55px 30px 55px 30px;
  position: relative;
  z-index: 1;
  background: linear-gradient(51deg, rgba(245,245,245,1) 0%, rgba(255,213,61,1) 49%);
}
.home-about-section .about-content.secondary-bg h3 {
  font-size: 30px;
  font-weight: 700;
  line-height: 1.1;
  color: #ffffff;
  margin-bottom: 20px;
}
.home-about-section .about-service-icon {
  background-color: #ffd53d;
  border-radius: 10px;
}
@media screen and (min-width: 576px) {
  .home-about-section .about-img-left {
    width: 42%;
  }
  .home-about-section .about-img-right {
    width: 58%;
    margin-left: -40px;
  }
}
@media screen and (max-width: 767px) {
  .home-about-section .about-img-wrap {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 767px) {
  .home-about-section .about-img-wrap img {
    width: 100%;
  }
}
@media screen and (max-width: 575px) {
  .home-about-section .about-content {
    margin: 0 30px;
  }
  .home-about-section .about-img-wrap {
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .home-about-section .about-img-right .about-img {
    border-left: none;
    border-right: none;
  }
}

.about-service-container {
  margin-bottom: 20px;
}

@media screen and (min-width: 992px) {
  .about-service-wrap .section-heading h2 {
    margin-bottom: 0;
  }
}

.about-service {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.about-service .about-service-icon {
  background: linear-gradient(51deg, rgba(0,0,163,1) 0%, rgba(0,103,179,1) 50%, rgba(64,176,223,1) 99%);
   color: #ffffff;
  width: 80px;
  height: 80px;
  line-height: 80px;
  padding: 10px;
  text-align: center;
}
.about-service .about-service-content {
  width: -moz-calc(100% - 100px);
  width: -webkit-calc(100% - 100px);
  width: calc(100% - 100px);
  padding-left: 20px;
}
.about-service .about-service-content h4 {
  margin-bottom: 8px;
}
.about-service .about-service-content p:last-child {
  margin-bottom: 0;
}

.about-video-wrap {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 520px;
  position: relative;
  margin-bottom: 100px;
}

.about-page-section .client-section {
  margin-bottom: 90px;
}
.about-page-section .client-wrap {
  background-color: #ffffff;
  padding: 0;
}

.fullwidth-callback {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 90px 0;
  position: relative;
}
.fullwidth-callback:before {
  background-color: #151515;
  opacity: 0.6;
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
.fullwidth-callback .callback-counter-wrap {
  position: relative;
  margin: -10px;
}
.fullwidth-callback .callback-counter-wrap .counter-item {
  width: 25%;
  border: none;
  padding: 10px;
}

.fullwidth-callback .callback-counter-wrap .counter-item-inner {
  background-color: ##ffd53d;
 display : flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
}

.fullwidth-callback .callback-counter-wrap .counter-icon {
  padding-right: 15px;
  width: 70px;
}
.fullwidth-callback .callback-counter-wrap .counter-content {
  border-left: 1px solid rgba(255, 255, 255, 0.4);
  padding-left: 15px;
  width: -moz-calc(100% - 70px);
  width: -webkit-calc(100% - 70px);
  width: calc(100% - 70px);
}
@media screen and (max-width: 575px) {
  .fullwidth-callback .callback-counter-wrap .counter-item {
    width: 50%;
  }
}
@media screen and (max-width: 479px) {
  .fullwidth-callback .callback-counter-wrap .counter-item {
    width: 100%;
  }
}

.home-about-section .about-img-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.home-about-section .about-img-left .about-img {
  margin-top: -90px;
}

.home-slider .home-banner-items .banner-content-wrap {
  padding-bottom: 230px;
}
.home-slider .banner-content {
  margin-left: auto;
  margin-right: auto;
  max-width: 75%;
}

.banner-inner-wrap {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.home-banner-items {
  position: relative;
}
.home-banner-items .banner-content-wrap {
  padding-bottom: 140px;
  padding-top: 230px;
}
.home-banner-items .banner-content {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 1;
}
.home-banner-items .banner-content .banner-title {
  font-size: 80px;
  color: #ffffff;
  line-height: 1.1;
  font-weight: 900;
  margin-bottom: 30px;
}
.home-banner-items .banner-content p {
  color: #ffffff;
  margin-bottom: 45px;
}
.home-banner-items .banner-content .slider-button {
  margin-left: -10px;
  margin-right: -10px;
}
.home-banner-items .banner-content .slider-button a {
  margin: 10px;
}
.home-banner-items .overlay {
  background-color: rgba(21, 21, 21, 0.51);
}
@media screen and (max-width: 991px) {
  .home-banner-items .banner-content .banner-title {
    font-size: 50px;
  }
}
@media screen and (max-width: 767px) {
  .home-banner-items .banner-content .banner-title {
    font-size: 45px;
  }
}
@media screen and (max-width: 575px) {
  .home-banner-items .banner-content-wrap {
    padding-top: 190px;
  }
  .home-banner-items .banner-content {
    max-width: 90%;
  }
  .home-banner-items .banner-content .banner-title {
    font-size: 32px;
  }
}

.home-banner-section {
  padding-bottom: 100px;
}
@media screen and (max-width: 575px) {
  .home-banner-section {
    padding-bottom: 60px;
  }
  .home-banner-section .banner-content-wrap {
    padding-bottom: 60px;
  }
  .home-banner-section .banner-content-wrap .slider-button {
    margin-left: -5px;
    margin-right: -5px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .home-banner-section .banner-content-wrap .slider-button a {
    padding: 10px 15px;
    text-align: center;
    margin: 5px;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1;
  }
}

.inner-baner-container {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
  padding: 190px 0 150px;
  text-align: center;
  position: relative;
}
.inner-baner-container:before {
  background-color: #151515;
  content: "";
  display: block;
  opacity: 0.65;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
}
.inner-baner-container .inner-banner-content {
  position: relative;
  color: #ffffff;
}
.inner-baner-container .inner-title {
  color: #ffffff;
  line-height: 1.1;
}
.inner-baner-container .breadcrumb {
  background-color: transparent;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  margin: 0;
  padding: 0;
}
.inner-baner-container .breadcrumb li {
  color: #ffffff;
  font-size: 14px;
}
.inner-baner-container .breadcrumb li:before {
  color: inherit;
}
.inner-baner-container .breadcrumb li a {
  color: #ffffff;
}
.inner-baner-container .breadcrumb li a:hover {
  color: #0067B3;
}
.inner-baner-container .breadcrumb li.active {
  color: #b3b3b3;
}
.inner-baner-container .breadcrumb-content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
}
@media screen and (max-width: 575px) {
  .inner-baner-container .inner-title {
    font-size: 30px;
  }
  .inner-baner-container .inner-banner-content p {
    font-size: 15px;
  }
}

.about-content{
  background-color: #2424;
}


.destination-section {
  padding-bottom: 180px;
  padding-top: 100px;
  background-color: #242424;
}
@media screen and (min-width: 992px) {
  .destination-section {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 575px) {
  .destination-section {
    padding-bottom: 60px;
  }
}

.destination-three-column .row .row {
  margin: 0;
}
.destination-three-column .row .row [class*=col-] {
  padding: 7px;
}
.destination-three-column > .row {
  margin: 0 -7px;
}
.destination-three-column > .row > [class*=col-] {
  padding: 0;
}

.destination-four-column .row {
  margin: 0 -7px;
}
.destination-four-column .row [class*=col-] {
  padding: 7px;
}

.overlay-desti-item .desti-content {
  position: absolute;
  bottom: 10px;
  left: 10px;
}
.overlay-desti-item .desti-content h3 {
  color: #ffffff;
  margin-bottom: 4px;
}
.overlay-desti-item .desti-content h3 a:hover {
  color: #ffd53d;
}

.desti-item {
  background-color: #ffffff;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
  padding: 8px;
  position: relative;
}
.desti-item .desti-image {
  position: relative;
}
.desti-item .desti-image:before {
  background-color: #101F46;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0.45;
}
.desti-item .desti-image .rating-start {
  position: absolute;
  top: 10px;
  right: 14px;
}
.desti-item .meta-cat a {
  display: inline-block;
  font-weight: 700;
  line-height: 1.2;
  text-transform: uppercase;
  text-decoration: none;
}
.desti-item .meta-cat a:hover, .desti-item .meta-cat a:focus {
  color: #0067B3;
}
.desti-item .bg-meta-cat {
  position: absolute;
  top: 30px;
  left: 30px;
}
.desti-item .bg-meta-cat a {
  background: linear-gradient(51deg, rgba(0,0,163,1) 0%, rgba(0,103,179,1) 50%, rgba(64,176,223,1) 99%);
  color: #ffffff;
  padding: 7px 24px;
}
.desti-item .bg-meta-cat a:hover, .desti-item .bg-meta-cat a:focus {
  background: linear-gradient(51deg, rgba(245,245,245,1) 0%, rgba(255,213,61,1) 49%);
  color: #0067B3;
}
.desti-item .rating-start span:before {
  color: #ffffff;
}
@media screen and (max-width: 991px) {
  .desti-item .desti-image img {
    width: 100%;
  }
}

.desti-content {
  padding: 20px;
}
.desti-content h3 {
  margin-bottom: 4px;
}
.desti-content h3 a:hover {
  color: #ffd53d;
}

.btn-wrap a {
  margin-top: 50px;
}
@media screen and (max-width: 575px) {
  .btn-wrap a {
    margin-top: 20px;
  }
}


.rating-start {
  display: block;
  position: relative;
  font-size: 15px;
}
.rating-start:before {
  color: #ccd6df;
  content: "\f005\f005\f005\f005\f005";
  display: block;
  font-family: "Font Awesome 5 free";
  font-weight: 900;
}
.rating-start span {
  display: block;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
}
.rating-start span:before {
  color: #ffd53d;
  content: "\f005\f005\f005\f005\f005";
  display: inline-block;
  font-family: "Font Awesome 5 free";
  font-weight: 900;
}

.package-section {
  padding-bottom: 100px;
}
@media screen and (max-width: 991px) {
  .package-section {
    padding-bottom: 70px;
  }
}
@media screen and (max-width: 575px) {
  .package-section {
    padding-bottom: 60px;
  }
}

.package-inner .row {
  margin: 0 -12px;
}
.package-inner .row [class*=col-] {
  padding-left: 12px;
  padding-right: 12px;
}

.package-wrap {
  box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 24px;
  position: relative;
}
.package-wrap .package-content {
  padding: 0 30px 0;
}
.package-wrap .package-content .btn-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  border-top: 1px solid rgba(175, 175, 175, 0.1);
  text-align: center;
  margin-left: -30px;
  margin-right: -30px;
  margin-top: 27px;
}
.package-wrap .package-content .btn-wrap a {
  color: #383838;
  display: block;
  margin-top: 0;
  padding: 16px 0;
  width: 100%;
}
.package-wrap .package-content .btn-wrap a.width-6 {
  width: 50%;
}
.package-wrap .package-content .btn-wrap a + a {
  border-left: 1px solid rgba(175, 175, 175, 0.1);
}
.package-wrap .package-content .btn-wrap a [class*=fa-] {
  color: #ffd53d;
  font-size: 13px;
  margin-left: 8px;
}
.package-wrap .package-content .btn-wrap a:hover, .package-wrap .package-content .btn-wrap a:focus, .package-wrap .package-content .btn-wrap a:active {
  color: #0067B3;
}
.package-wrap .package-content .btn-wrap a:hover [class*=fa-], .package-wrap .package-content .btn-wrap a:focus [class*=fa-], .package-wrap .package-content .btn-wrap a:active [class*=fa-] {
  color: #0067B3;
}
.package-wrap .package-content .btn-wrap a:hover [class*=fa-]:before, .package-wrap .package-content .btn-wrap a:focus [class*=fa-]:before, .package-wrap .package-content .btn-wrap a:active [class*=fa-]:before {
  font-weight: 900;
}
.package-wrap .review-area {
  margin-bottom: 15px;
}
.package-wrap .review-area .review-text {
  font-size: 13px;
  color: #787878;
  line-height: 1.1;
}
.package-wrap .rating-start {
  display: inline-block;
  margin-left: 5px;
}
.package-wrap .package-price {
  background-color: #ffd53d;
  position: absolute;
  top: 20px;
  right: 0;
}
.package-wrap .package-price h6 {
  color: #ffffff;
  font-size: 13px;
  font-family: "Open Sans", sans-serif;
  line-height: 1.1;
  margin-bottom: 0;
  padding: 8px 16px;
}
.package-wrap .package-price h6 span {
  font-family: "Raleway", sans-serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.2;
}
@media screen and (max-width: 991px) {
  .package-wrap {
    margin-bottom: 30px;
  }
  .package-wrap .feature-image img {
    width: 100%;
  }
}
@media screen and (max-width: 575px) {
  .package-wrap .package-content {
    padding: 0 20px 0;
  }
  .package-wrap .package-content .btn-wrap {
    margin-left: -20px;
    margin-right: -20px;
  }
}

.package-meta {
  background-color: #0067B3;
  margin-bottom: 25px;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: -25px;
  padding: 15px 0;
  position: relative;
}
.package-meta ul {
  font-size: 0;
  margin: 0;
  padding: 0;
}
.package-meta ul li {
  color: #ffffff;
  display: inline-block;
  font-size: 13px;
  line-height: 1.1;
  padding: 0 12px;
  position: relative;
}
.package-meta ul li [class*=fa-] {
  margin-right: 4px;
}
.package-meta ul li:before {
  color: #BBBBBB;
  content: "|";
  line-height: 0.8;
  position: absolute;
  right: -3px;
  top: 0;
}
.package-meta ul li:first-child {
  padding-left: 0;
}
.package-meta ul li:last-child {
  padding-right: 0;
}
.package-meta ul li:last-child:before {
  display: none;
}
@media screen and (max-width: 575px) {
  .package-meta {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
  }
}

.package-section.bg-light-grey {
  padding-top: 100px;
  margin-top: 50px;
}
@media screen and (max-width: 991px) {
  .package-section.bg-light-grey {
    padding-top: 70px;
    margin-top: 0;
  }
}
@media screen and (max-width: 575px) {
  .package-section.bg-light-grey {
    padding-top: 60px;
  }
}

.package-inner-list .row {
  margin: 0 -15px;
}
.package-inner-list .row [class*=col-] {
  padding-left: 15px;
  padding-right: 15px;
}
.package-inner-list .package-wrap {
  background-color: #ffffff;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 40px;
}
.package-inner-list .package-wrap .feature-image,
.package-inner-list .package-wrap .package-content {
  width: 50%;
}
.package-inner-list .package-wrap .package-price {
  right: auto;
  left: 0;
}
.package-inner-list .package-wrap .package-meta {
  margin: 0;
}
.package-inner-list .package-wrap .package-meta ul li [class*=fa-] {
  font-size: 10px;
  margin-right: 2px;
}
.package-inner-list .package-wrap .package-content {
  padding-top: 30px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.package-inner-list .package-wrap .package-content .btn-wrap {
  margin-top: auto;
}
@media screen and (max-width: 575px) {
  .package-inner-list .package-wrap .feature-image,
.package-inner-list .package-wrap .package-content {
    width: 100%;
  }
}

/*--------------------------------------------------------------
>>> Home travel callback section  
----------------------------------------------------------------*/
.callback-section {
  position: relative;
  margin-bottom: 140px;
}
.callback-section:before {
  background-color: #0067B3;
  content: "";
  width: 70%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}
.callback-section .callback-img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 540px;
}
@media screen and (max-width: 991px) {
  .callback-section:before {
    width: 100%;
  }
  .callback-section .callback-img {
    min-height: 300px;
    background-position: top center;
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
  }
}
@media screen and (max-width: 575px) {
  .callback-section {
    margin-bottom: 120px;
  }
}

.callback-inner {
  padding: 100px 0px 0px 65px;
}
@media screen and (max-width: 991px) {
  .callback-inner {
    padding: 80px 0px 0 0px;
  }
}
@media screen and (max-width: 575px) {
  .callback-inner {
    padding: 30px 0px 0 0px;
  }
}

.callback-counter-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 50px;
}
.callback-section .callback-counter-wrap .counter-item {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 25px 25px 18px 25px;
  width: 50%;
}
.callback-counter-wrap .counter-icon {
  width: 52px;
}
.callback-counter-wrap .counter-content {
  width: -moz-calc(100% - 52px);
  width: -webkit-calc(100% - 52px);
  width: calc(100% - 52px);
  text-align: center;
}
.callback-counter-wrap .counter-no {
  color: #ffffff;
  display: block;
  font-size: 32px;
  line-height: 1.1;
  font-weight: 900;
}
.callback-counter-wrap .counter-text {
  color: #ffffff;
  display: block;
}
@media screen and (min-width: 768px) {
  .callback-counter-wrap .counter-item {
    border-right: 1px solid rgba(255, 255, 255, 0.4);
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  }
  .callback-counter-wrap .counter-item:nth-child(2n+2) {
    border-right: none;
  }
  .callback-counter-wrap .counter-item:last-child, .callback-counter-wrap .counter-item:nth-last-child(2) {
    border-bottom: none;
  }
}
@media screen and (max-width: 767px) {
  .callback-counter-wrap .counter-item {
    width: 100%;
  }
  .callback-counter-wrap .counter-icon {
    width: 40px;
  }
  .callback-counter-wrap .counter-item {
    border: 1px solid rgba(255, 255, 255, 0.4);
    margin-bottom: 10px;
  }
  .callback-counter-wrap .counter-content {
    width: -moz-calc(100% - 40px);
    width: -webkit-calc(100% - 40px);
    width: calc(100% - 40px);
    padding-left: 20px;
    text-align: right;
  }
}
@media screen and (max-width: 575px) {
  .callback-counter-wrap {
    margin-bottom: 30px;
  }
}

.callback-img {
  position: relative;
}

.support-area {
  background-color: #ffffff;
  box-shadow: 0px 15px 55px -15px rgba(0, 0, 0, 0.1);
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  margin-bottom: -50px;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 35px;
}
.support-area .support-icon {
  width: 52px;
  margin-right: 26px;
}
.support-area h3 {
  color: #ffd53d;
  font-family: "Open Sans", sans-serif;
  font-size: 30px;
  font-weight: 900;
  line-height: 1.1;
  margin-bottom: 0;
}
.support-area h4 {
  color: #383838;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.3;
}
@media screen and (min-width: 992px) {
  .support-area {
    display: -webkit-inline-box;
    display: -moz-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
}
@media screen and (max-width: 575px) {
  .support-area {
    padding: 20px;
  }
  .support-area h3 {
    font-size: 20px;
  }
  .support-area h4 {
    font-size: 15px;
  }
}

.video-button {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.video-button a {
  display: block;
  position: relative;
  cursor: pointer;
}
.video-button a i {
  background-color: #ffffff;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  border-radius: 100%;
  color: #0067B3;
  display: block;
  font-size: 24px;
  line-height: 104px;
  width: 104px;
  height: 104px;
  text-align: center;
}
.video-button a:after {
  background-color: #ffffff;
  position: absolute;
  display: block;
  content: "";
  height: 70px;
  width: 70px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  border-radius: 100%;
  z-index: -1;
  top: 16px;
  left: 16px;
  -webkit-animation: videoPulse 1.6s ease-out infinite;
  animation: videoPulse 1.6s ease-out infinite;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}
@media screen and (max-width: 575px) {
  .video-button a i {
    font-size: 18px;
    height: 80px;
    width: 80px;
    line-height: 80px;
  }
  .video-button a:after {
    height: 50px;
    width: 50px;
  }
}

.bg-color-callback {
  background-color: #0067B3;
  padding-top: 50px;
  padding-bottom: 50px;
}
.bg-color-callback .callback-content h2 {
  color: #ffffff;
  font-size: 30px;
  font-weight: 700;
  line-height: 1.1;
}
.bg-color-callback .callback-content p {
  color: #ffffff;
}
.bg-color-callback .callback-content p:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 768px) {
  .bg-color-callback .callback-content {
    padding-right: 50px;
  }
}
.bg-color-callback .button-wrap {
  text-align: center;
}
@media screen and (min-width: 768px) {
  .bg-color-callback .button-wrap {
    text-align: right;
  }
}
@media screen and (max-width: 767px) {
  .bg-color-callback {
    text-align: center;
  }
  .bg-color-callback .callback-content {
    margin-bottom: 30px;
  }
  .bg-color-callback .callback-content h2 {
    font-size: 20px;
  }
}

.bg-img-callback {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  padding: 70px 0;
}
.bg-img-callback:before {
  content: "";
  background-color: #151515;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0.75;
}
.bg-img-callback .callback-content {
  color: #ffffff;
}
.bg-img-callback .callback-content h2 {
  color: #ffffff;
}
.bg-img-callback .button-wrap {
  text-align: center;
}
@media screen and (min-width: 768px) {
  .bg-img-callback .button-wrap {
    text-align: right;
  }
}
@media screen and (max-width: 767px) {
  .bg-img-callback {
    text-align: center;
  }
}

@-webkit-keyframes videoPulse {
  0%, 35% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }
  50% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  }
  100% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-transform: scale(2);
    transform: scale(2);
  }
}
@keyframes videoPulse {
  0%, 35% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }
  50% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  }
  100% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-transform: scale(2);
    transform: scale(2);
  }
}


.container {
  max-width: 1170px;
  padding-left: 15px;
  padding-right: 15px;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.section-heading {
  margin-bottom: 50px;
}
.section-heading h2 {
  font-size: 48px;
  font-weight: 900;
  line-height: 1.1;
  text-transform: uppercase;
}
.section-heading h5 {
  color: #ffd53d;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 22px;
  text-transform: uppercase;
  display: inline-block;
}
.section-heading p:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 767px) {
  .section-heading h2 {
    font-size: 30px;
  }
}
@media screen and (max-width: 575px) {
  .section-heading {
    margin-bottom: 30px;
  }
  .section-heading h2 {
    font-size: 24px;
  }
}

.section-heading .dash-style {
  padding-left: 80px;
  position: relative;
}
.section-heading .dash-style:before {
  background-color: #ffd53d;
  content: "";
  height: 2px;
  width: 70px;
  margin-right: 10px;
  position: absolute;
  left: 0;
  top: 50%;
}

.title-icon-divider {
  color: #0067B3;
  line-height: 1;
  position: relative;
  margin-top: 25px;
}
.title-icon-divider:before, .title-icon-divider:after {
  content: "....";
  display: inline-block;
  font-size: 37px;
  line-height: 0;
  letter-spacing: 3px;
}
.title-icon-divider [class*=fa-] {
  font-size: 20px;
  margin-left: 10px;
  margin-right: 10px;
}
.title-icon-divider ~ p {
  margin-top: 30px;
}

.title-icon-divider {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.text-center .title-icon-divider {
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
}

.section-heading-white {
  color: #ffffff;
}
.section-heading-white .dash-style {
  color: #ffffff;
}
.section-heading-white .dash-style:before {
  background-color: #ffffff;
}
.section-heading-white h2,
.section-heading-white p {
  color: #ffffff;
}
.section-heading-white .title-icon-divider {
  color: #ffffff;
}


/* Custom Button */

/* Custom Button  */

.custom-btn {
  width: 190px;
  height: 45px;
  color: #fff;
  border-radius: 5px;
  padding: 10px 25px;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
   box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1);
  outline: none;
}
.btn-15 {
  background: linear-gradient(51deg, rgba(0,0,163,1) 0%, rgba(0,103,179,1) 50%, rgba(64,176,223,1) 99%);
  border: none;
  z-index: -1;
}
.btn-15:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
  background: linear-gradient(51deg, rgba(245,245,245,1) 0%, rgba(255,213,61,1) 49%);
  border-radius: 5px;
   box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1);
  transition: all 0.3s ease;
}
.btn-15:hover {
  color:#0067B3;
}
.btn-15:hover:after {
  left: 0;
  width: 100%;
}
.btn-15:active {
  top: 2px;
} 



/*--------------------------------------------------------------
>>> Home travel callback section  
----------------------------------------------------------------*/
.callback-section {
  position: relative;
  margin-bottom: 140px;
}
.callback-section:before {
  background-color: #0067B3;
  content: "";
  width: 70%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}
.callback-section .callback-img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 540px;
}
@media screen and (max-width: 991px) {
  .callback-section:before {
    width: 100%;
  }
  .callback-section .callback-img {
    min-height: 300px;
    background-position: top center;
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
  }
}
@media screen and (max-width: 575px) {
  .callback-section {
    margin-bottom: 120px;
  }
}

.callback-inner {
  padding: 100px 0px 0px 65px;
}
@media screen and (max-width: 991px) {
  .callback-inner {
    padding: 80px 0px 0 0px;
  }
}
@media screen and (max-width: 575px) {
  .callback-inner {
    padding: 30px 0px 0 0px;
  }
}

.callback-counter-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 50px;
}
.callback-section .callback-counter-wrap .counter-item {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 25px 25px 18px 25px;
  width: 50%;
}
.callback-counter-wrap .counter-icon {
  width: 52px;
}
.callback-counter-wrap .counter-content {
  width: -moz-calc(100% - 52px);
  width: -webkit-calc(100% - 52px);
  width: calc(100% - 52px);
  text-align: center;
}
.callback-counter-wrap .counter-no {
  color: #ffffff;
  display: block;
  font-size: 32px;
  line-height: 1.1;
  font-weight: 900;
}
.callback-counter-wrap .counter-text {
  color: #ffffff;
  display: block;
}
@media screen and (min-width: 768px) {
  .callback-counter-wrap .counter-item {
    border-right: 1px solid rgba(255, 255, 255, 0.4);
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  }
  .callback-counter-wrap .counter-item:nth-child(2n+2) {
    border-right: none;
  }
  .callback-counter-wrap .counter-item:last-child, .callback-counter-wrap .counter-item:nth-last-child(2) {
    border-bottom: none;
  }
}
@media screen and (max-width: 767px) {
  .callback-counter-wrap .counter-item {
    width: 100%;
  }
  .callback-counter-wrap .counter-icon {
    width: 40px;
  }
  .callback-counter-wrap .counter-item {
    border: 1px solid rgba(255, 255, 255, 0.4);
    margin-bottom: 10px;
  }
  .callback-counter-wrap .counter-content {
    width: -moz-calc(100% - 40px);
    width: -webkit-calc(100% - 40px);
    width: calc(100% - 40px);
    padding-left: 20px;
    text-align: right;
  }
}
@media screen and (max-width: 575px) {
  .callback-counter-wrap {
    margin-bottom: 30px;
  }
}

.callback-img {
  position: relative;
}

.support-area {
  background-color: #ffffff;
  box-shadow: 0px 15px 55px -15px rgba(0, 0, 0, 0.1);
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  margin-bottom: -50px;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 35px;
}
.support-area .support-icon {
  width: 52px;
  margin-right: 26px;
}
.support-area h3 {
  color: #F56960;
  font-family: "Open Sans", sans-serif;
  font-size: 30px;
  font-weight: 900;
  line-height: 1.1;
  margin-bottom: 0;
}
.support-area h4 {
  color: #383838;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.3;
}
@media screen and (min-width: 992px) {
  .support-area {
    display: -webkit-inline-box;
    display: -moz-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
}
@media screen and (max-width: 575px) {
  .support-area {
    padding: 20px;
  }
  .support-area h3 {
    font-size: 20px;
  }
  .support-area h4 {
    font-size: 15px;
  }
}

.video-button {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.video-button a {
  display: block;
  position: relative;
  cursor: pointer;
}
.video-button a i {
  background-color: #ffffff;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  border-radius: 100%;
  color: #0067B3;
  display: block;
  font-size: 24px;
  line-height: 104px;
  width: 104px;
  height: 104px;
  text-align: center;
}
.video-button a:after {
  background-color: #ffffff;
  position: absolute;
  display: block;
  content: "";
  height: 70px;
  width: 70px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  border-radius: 100%;
  z-index: -1;
  top: 16px;
  left: 16px;
  -webkit-animation: videoPulse 1.6s ease-out infinite;
  animation: videoPulse 1.6s ease-out infinite;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}
@media screen and (max-width: 575px) {
  .video-button a i {
    font-size: 18px;
    height: 80px;
    width: 80px;
    line-height: 80px;
  }
  .video-button a:after {
    height: 50px;
    width: 50px;
  }
}

.bg-color-callback {
  background-color: #0067B3;
  padding-top: 50px;
  padding-bottom: 50px;
}
.bg-color-callback .callback-content h2 {
  color: #ffffff;
  font-size: 30px;
  font-weight: 700;
  line-height: 1.1;
}
.bg-color-callback .callback-content p {
  color: #ffffff;
}
.bg-color-callback .callback-content p:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 768px) {
  .bg-color-callback .callback-content {
    padding-right: 50px;
  }
}
.bg-color-callback .button-wrap {
  text-align: center;
}
@media screen and (min-width: 768px) {
  .bg-color-callback .button-wrap {
    text-align: right;
  }
}
@media screen and (max-width: 767px) {
  .bg-color-callback {
    text-align: center;
  }
  .bg-color-callback .callback-content {
    margin-bottom: 30px;
  }
  .bg-color-callback .callback-content h2 {
    font-size: 20px;
  }
}

.bg-img-callback {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  padding: 70px 0;
}
.bg-img-callback:before {
  content: "";
  background-color: #151515;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0.75;
}
.bg-img-callback .callback-content {
  color: #ffffff;
}
.bg-img-callback .callback-content h2 {
  color: #ffffff;
}
.bg-img-callback .button-wrap {
  text-align: center;
}
@media screen and (min-width: 768px) {
  .bg-img-callback .button-wrap {
    text-align: right;
  }
}
@media screen and (max-width: 767px) {
  .bg-img-callback {
    text-align: center;
  }
}

@-webkit-keyframes videoPulse {
  0%, 35% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }
  50% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  }
  100% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-transform: scale(2);
    transform: scale(2);
  }
}
@keyframes videoPulse {
  0%, 35% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }
  50% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  }
  100% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-transform: scale(2);
    transform: scale(2);
  }
}
/*--------------------------------------------------------------
>>> Home travel activity section  
----------------------------------------------------------------*/
.activity-section {
  padding-bottom: 100px;
}
@media screen and (max-width: 991px) {
  .activity-section {
    padding-bottom: 82px;
  }
}
@media screen and (max-width: 575px) {
  .activity-section {
    padding-bottom: 42px;
  }
}

.activity-inner {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.activity-inner.row {
  margin-left: -9px;
  margin-right: -9px;
}
.activity-inner [class*=col-] {
  padding-left: 9px;
  padding-right: 9px;
}
.activity-inner .activity-item {
  border: 1px solid #D9D9D9;
  text-align: center;
  padding: 15px;
}
.activity-inner .activity-item .activity-icon {
  margin-bottom: 15px;
}
.activity-inner .activity-item h4 {
  margin-bottom: 8px;
  font-weight: 700;
  line-height: 1.2;
}
.activity-inner .activity-item p {
  margin-bottom: 0;
}
@media screen and (max-width: 991px) {
  .activity-inner .activity-item {
    margin-bottom: 18px;
  }
}

.activity-bg-image {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  padding-top: 100px;
  margin-bottom: 100px;
  position: relative;
}
.activity-bg-image:before {
  content: "";
  background-color: #151515;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0.75;
}
.activity-bg-image .activity-inner.row {
  margin-left: 0;
  margin-right: 0;
}
.activity-bg-image .activity-inner [class*=col-] {
  padding-left: 0;
  padding-right: 0;
}
.activity-bg-image .activity-item {
  border-width: 0 1px 0 0;
  border-color: rgba(255, 255, 255, 0.28);
  margin-bottom: 0;
}
.activity-bg-image .activity-content {
  color: #ffffff;
}
.activity-bg-image .activity-content h4 {
  color: #ffffff;
}
.activity-bg-image [class*=col-]:last-child .activity-item {
  border-right: none;
}
@media (max-width: 991px) and (min-width: 768px) {
  .activity-bg-image .activity-item {
    border-width: 0 1px 1px 0;
  }
  .activity-bg-image [class*=col-]:nth-child(3) ~ [class*=col-] .activity-item {
    border-bottom: none;
  }
  .activity-bg-image [class*=col-]:nth-child(3) .activity-item {
    border-right: none;
  }
}
@media screen and (max-width: 991px) {
  .activity-bg-image {
    padding-top: 82px;
    margin-bottom: 82px;
  }
}
@media screen and (max-width: 767px) {
  .activity-bg-image .activity-item {
    border-width: 0 1px 1px 0;
  }
  .activity-bg-image [class*=col-]:nth-child(4) ~ [class*=col-] .activity-item {
    border-bottom: none;
  }
  .activity-bg-image [class*=col-]:nth-child(2n+2) .activity-item {
    border-right: none;
  }
}
@media screen and (max-width: 575px) {
  .activity-bg-image {
    padding-top: 50px;
    margin-bottom: 50px;
  }
}


/* Header */

.inner-baner-container{
  background-image: url(../public/img/walk.jpg);
}

/*--------------------------------------------------------------
>>> Inner Banner  
----------------------------------------------------------------*/
.inner-banner-wrap {
  padding-bottom: 50px;
}
@media screen and (max-width: 575px) {
  .inner-banner-wrap {
    padding-bottom: 0;
  }
}

.inner-shape {
  background-image: url(https://demo.bosathemes.com/html/travele/assets/images/slider-pattern.png);
  background-size: cover;
  background-repeat: no-repeat;
  content: "";
  height: 150px;
  width: 100%;
  margin-top: -100px;
  position: relative;
}
@media screen and (max-width: 991px) {
  .inner-shape {
    height: 105px;
  }
}

.inner-banner-gray {
  background-color: #FAFAFA;
}
.inner-banner-gray .inner-shape {
  background-image: url(https://demo.bosathemes.com/html/travele/assets/images/banner-pattern.png);
}

.inner-baner-container {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
  padding: 190px 0 150px;
  text-align: center;
  position: relative;
}
.inner-baner-container:before {
  background-color: #151515;
  content: "";
  display: block;
  opacity: 0.65;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
}
.inner-baner-container .inner-banner-content {
  position: relative;
  color: #ffffff;
}
.inner-baner-container .inner-title {
  color: #ffffff;
  line-height: 1.1;
}
.inner-baner-container .breadcrumb {
  background-color: transparent;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  margin: 0;
  padding: 0;
}
.inner-baner-container .breadcrumb li {
  color: #ffffff;
  font-size: 14px;
}
.inner-baner-container .breadcrumb li:before {
  color: inherit;
}
.inner-baner-container .breadcrumb li a {
  color: #ffffff;
}
.inner-baner-container .breadcrumb li a:hover {
  color: #2C75BA;
}
.inner-baner-container .breadcrumb li.active {
  color: #b3b3b3;
}
.inner-baner-container .breadcrumb-content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
}
@media screen and (max-width: 575px) {
  .inner-baner-container .inner-title {
    font-size: 30px;
  }
  .inner-baner-container .inner-banner-content p {
    font-size: 15px;
  }
}

.inner-baner-container .inner-title {
  color: #ffffff;
  line-height: 1.1;
  font-size: 48px;
font-weight: 900;
line-height: 1.1;
text-transform: uppercase;
}

footer {
  background-color: #151515;
  padding: 50px 0;
  color : #fff;
}

/*--------------------------------------------------------------
>>> About Pages
----------------------------------------------------------------*/
.about-service-container {
  margin-bottom: 20px;
}

@media screen and (min-width: 992px) {
  .about-service-wrap .section-heading h2 {
    margin-bottom: 0;
  }
}

.about-service {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.about-service .about-service-icon {
  background-color: #0791BE;
  color: #ffffff;
  width: 80px;
  height: 80px;
  line-height: 80px;
  padding: 10px;
  text-align: center;
}
.about-service .about-service-content {
  width: -moz-calc(100% - 100px);
  width: -webkit-calc(100% - 100px);
  width: calc(100% - 100px);
  padding-left: 20px;
}
.about-service .about-service-content h4 {
  margin-bottom: 8px;
}
.about-service .about-service-content p:last-child {
  margin-bottom: 0;
}

.about-video-wrap {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 520px;
  position: relative;
  margin-bottom: 100px;
}

.about-page-section .client-section {
  margin-bottom: 90px;
}
.about-page-section .client-wrap {
  background-color: #ffffff;
  padding: 0;
}

.fullwidth-callback {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 90px 0;
  position: relative;
}
.fullwidth-callback:before {
  background-color: #151515;
  opacity: 0.6;
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
.fullwidth-callback .callback-counter-wrap {
  position: relative;
  margin: -10px;
}
.fullwidth-callback .callback-counter-wrap .counter-item {
  width: 25%;
  border: none;
  padding: 10px;
}
.fullwidth-callback .callback-counter-wrap .counter-item-inner {
  background: linear-gradient(51deg, rgba(0,0,163,1) 0%, rgba(0,103,179,1) 50%, rgba(64,176,223,1) 99%);
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 25px;
}
.fullwidth-callback .callback-counter-wrap .counter-icon {
  padding-right: 15px;
  width: 70px;
}
.fullwidth-callback .callback-counter-wrap .counter-content {
  border-left: 1px solid rgba(255, 255, 255, 0.4);
  padding-left: 15px;
  width: -moz-calc(100% - 70px);
  width: -webkit-calc(100% - 70px);
  width: calc(100% - 70px);
}
@media screen and (max-width: 575px) {
  .fullwidth-callback .callback-counter-wrap .counter-item {
    width: 50%;
  }
}
@media screen and (max-width: 479px) {
  .fullwidth-callback .callback-counter-wrap .counter-item {
    width: 100%;
  }
}

.home-about-section .about-img-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.home-about-section .about-img-left .about-img {
  margin-top: -90px;
}
.home-about-section .about-img-right .about-img {
  border: 10px solid #ffffff;
}
.home-about-section .about-content {
  border: 10px solid #ffffff;
  margin: 40px 0 0 30px;
  padding: 55px 30px 55px 30px;
  position: relative;
  z-index: 1;
}
.home-about-section .about-content.secondary-bg h3 {
  font-size: 30px;
  font-weight: 700;
  line-height: 1.1;
  color: #ffffff;
  margin-bottom: 20px;
}
.home-about-section .about-service-icon {
  background-color: #F56960;
}
@media screen and (min-width: 576px) {
  .home-about-section .about-img-left {
    width: 42%;
  }
  .home-about-section .about-img-right {
    width: 58%;
    margin-left: -40px;
  }
}
@media screen and (max-width: 767px) {
  .home-about-section .about-img-wrap {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 767px) {
  .home-about-section .about-img-wrap img {
    width: 100%;
  }
}
@media screen and (max-width: 575px) {
  .home-about-section .about-content {
    margin: 0 30px;
  }
  .home-about-section .about-img-wrap {
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .home-about-section .about-img-right .about-img {
    border-left: none;
    border-right: none;
  }
}

/*--------------------------------------------------------------
>>> Package Offer Pages
----------------------------------------------------------------*/
.package-offer-wrap .special-section {
  padding-bottom: 155px;
}
@media screen and (max-width: 575px) {
  .package-offer-wrap .special-section {
    padding-bottom: 130px;
  }
}


/*--------------------------------------------------------------
>>> Contact Pages
----------------------------------------------------------------*/
.contact-form-inner {
  padding-bottom: 80px;
}

.contact-page-section iframe {
  display: block;
}

.contact-detail-wrap .details-list {
  margin: 45px 0;
}
.contact-detail-wrap .details-list ul {
  margin: 0;
  padding: 0;
}
.contact-detail-wrap .details-list ul li {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  list-style: none;
  margin-bottom: 20px;
  position: relative;
}
.contact-detail-wrap .details-list ul li:last-child {
  margin-bottom: 0;
}
.contact-detail-wrap .details-list .icon {
  background-color: #0791BE;
  color: #ffffff;
  display: block;
  font-size: 25px;
  line-height: 60px;
  height: 60px;
  text-align: center;
  width: 60px;
}
.contact-detail-wrap .details-list .details-content {
  width: -moz-calc(100% - 60px);
  width: -webkit-calc(100% - 60px);
  width: calc(100% - 60px);
  padding-left: 20px;
}
.contact-detail-wrap .details-list .details-content h4 {
  margin-bottom: 8px;
}
.contact-detail-wrap .contct-social ul {
  margin: 0 -5px;
}
.contact-detail-wrap .contct-social li {
  margin: 0 5px;
}
.contact-detail-wrap .contct-social a {
  color: #0067B3;
  border: 1px solid #0067B3;
  display: block;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  height: 40px;
  width: 40px;
}
@media screen and (min-width: 768px) {
  .contact-detail-wrap {
    padding-left: 30px;
  }
}

.contact-from-wrap .contact-from input:not([type=submit]):not([type=checkbox]):not([type=radio]) {
  width: 100%;
}
.contact-from-wrap .contact-from input[type=submit] {
  padding: 15px 30px;
}
@media screen and (max-width: 767px) {
  .contact-from-wrap {
    margin-bottom: 40px;
  }
}

/*--------------------------------------------------------------
>>> gallery Pages
----------------------------------------------------------------*/
.gallery-section {
  margin-bottom: 80px;
}
@media screen and (max-width: 575px) {
  .gallery-section {
    margin-bottom: 50px;
  }
}

.gallery-inner-wrap {
  margin: -6px;
}
.gallery-inner-wrap:before, .gallery-inner-wrap:after {
  content: "";
  display: block;
  clear: both;
}
.gallery-inner-wrap .single-gallery {
  width: 33.33%;
  float: left;
  padding: 6px;
}
.gallery-inner-wrap .single-gallery.width-3 {
  width: 66.66%;
}
.gallery-inner-wrap .gallery-img {
  position: relative;
}
.gallery-inner-wrap .gallery-img:before {
  background-color: rgba(0, 0, 0, 0.4);
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}
.gallery-inner-wrap .gallery-img img {
  width: 100%;
}
.gallery-inner-wrap .gallery-img .gallery-title {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  z-index: 1;
}
.gallery-inner-wrap .gallery-img .gallery-title h3 {
  color: #ffffff;
  opacity: 0;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}
.gallery-inner-wrap .gallery-img:hover:before {
  opacity: 1;
}
.gallery-inner-wrap .gallery-img:hover .gallery-title h3 {
  opacity: 1;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}
@media screen and (max-width: 767px) {
  .gallery-inner-wrap {
    margin: -5px;
  }
  .gallery-inner-wrap .single-gallery {
    width: 50%;
    padding: 5px;
  }
}
@media screen and (max-width: 479px) {
  .gallery-inner-wrap .single-gallery {
    width: 100%;
  }
}
.contct-social  ul {
  display:flex;
  flex-direction: row;
  padding-top: 20px;
}

.map-section {
}

.navigation{
  z-index: 1000;
  position: absolute;
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  color:#fff;
  font-size: 1.2rem;
  padding-bottom: 1rem;
}

.navigation:hover{
  background: rgb(2,0,36);
  background: linear-gradient(164deg, rgba(2,0,36,1) 0%, rgba(9,9,121,0.3043418050814075) 35%, rgba(0,212,255,1) 100%); 
}

.navigation a:hover {
  color: #ffd53d;
  text-decoration: none;
}

.hidden-content {
  /* visibility: hidden; */
  display: none;
}



.package-content a {
  font-weight: 700;
}

.package-content a:hover {
  color: #ffd53d;
  text-decoration: none;
}

.package-content p {
  margin-top: 10px;
  min-height: 150px;
}

.button-text{
  z-index: 1000;
  position: relative;
  border-radius: 5px;
}


.hero-slide {
  position: relative;
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-slide-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4); /* Set the background color to semi-transparent black */
}

.icon-solid{
  font-size: 3rem;
}

.about-service-content h4{
  font-weight: 700;
}

.about-service-content p{
  font-size: 0.8rem;
}


/*--------------------------------------------------------------
>>> Package detail Pages
----------------------------------------------------------------*/
.single-tour-section {
  padding-bottom: 80px;
}
.single-tour-section .start-wrap {
  display: inline-block;
}
.single-tour-section .rating-start:before {
  font-weight: 400;
}
.single-tour-section .rating-start span:before {
  color: #ffffff;
}

.single-tour-inner {
  /* the parent */
  /* item */
}
.single-tour-inner h2 {
  margin-bottom: 35px;
}
.single-tour-inner .package-meta {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}
.single-tour-inner .package-meta ul li:before {
  font-size: 20px;
  color: #DDDDDD;
  top: -2px;
}
@media screen and (min-width: 576px) {
  .single-tour-inner .package-meta ul li {
    font-size: 15px;
    padding: 0 30px;
  }
}
.single-tour-inner .tab-container {
  margin-bottom: 50px;
}
.single-tour-inner .slick-slider {
  overflow: hidden;
}
.single-tour-inner .slick-slider .slick-dots {
  position: static;
}
.single-tour-inner .slick-slider .slick-dots li button {
  height: 7px;
  width: 7px;
  padding: 0;
}
.single-tour-inner .slick-list {
  margin: 0 -9px;
}
.single-tour-inner .single-tour-item {
  padding: 0 9px;
}

/*--------------------------------------------------------------
>>> Booking Pages
----------------------------------------------------------------*/
.booking-form-wrap {
  background-color: #f8f8f8;
  padding: 30px;
}
.booking-form-wrap .booking-form h4 {
  margin-top: 5px;
}
.booking-form-wrap .form-group label .custom-checkbox {
  border-color: #B5B5B5;
}
.booking-form-wrap .submit-btn {
  margin-top: 10px;
  margin-bottom: 0;
}

.booking-content {
  margin-bottom: 60px;
}
.booking-content .form-title {
  margin-bottom: 30px;
}
.booking-content .form-title h3 {
  text-transform: uppercase;
  display: inline-block;
}
.booking-content .form-title span {
  background-color: #F56960;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  border-radius: 100%;
  color: #ffffff;
  display: inline-block;
  font-size: 20px;
  height: 50px;
  line-height: 50px;
  margin-right: 20px;
  text-align: center;
  width: 50px;
}
.booking-content .info-content {
  border-top: 1px solid #e6e6e6;
  margin-top: 30px;
  padding-top: 30px;
}
@media screen and (max-width: 575px) {
  .booking-content {
    margin-bottom: 30px;
  }
  .booking-content .form-title {
    margin-bottom: 15px;
  }
  .booking-content .form-title span {
    height: 30px;
    line-height: 30px;
    margin-right: 16px;
    width: 30px;
  }
}

.form-policy .form-group {
  margin-bottom: 30px;
}

.widget-support-wrap {
  background-color: #f9f9f9;
  padding: 30px;
  text-align: center;
}
.widget-support-wrap .icon {
  font-size: 52px;
  margin-bottom: 15px;
  line-height: 1;
}
.widget-support-wrap .support-content h5 {
  color: #383838;
  font-weight: 400;
}
.widget-support-wrap .support-content .phone {
  font-family: "Raleway", sans-serif;
  font-size: 30px;
  display: block;
  margin-bottom: 10px;
  color: #101F46;
  line-height: 1;
  font-weight: 700;
}

.sidebar .widget-bg {
  background-color: #F8F8F8;
  border-bottom: 3px solid #0791BE;
  margin-bottom: 50px;
  padding: 30px;
}
.sidebar .widget-bg .bg-title {
  background-color: #0791BE;
  color: #ffffff;
  padding: 20px;
  text-align: center;
  margin-bottom: 25px;
}

.sidebar .information-content {
  background-color: #f8f8f8;
  padding: 50px 30px;
  margin-bottom: 50px;
}
.sidebar .information-content .button-primary {
  margin-top: 20px;
}
.sidebar .information-content h5,
.sidebar .travel-package-content h5 {
  color: #F56960;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 22px;
  display: inline-block;
  text-transform: uppercase;
  padding-left: 60px;
  position: relative;
}
.sidebar .information-content h5:before,
.sidebar .travel-package-content h5:before {
  background-color: #F56960;
  content: "";
  height: 2px;
  width: 50px;
  margin-right: 10px;
  position: absolute;
  left: 0;
  top: 50%;
}
.sidebar .information-content h3,
.sidebar .travel-package-content h3 {
  text-transform: uppercase;
}

.sidebar .travel-package-content h5 {
  color: #F56960;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 22px;
  display: inline-block;
  text-transform: uppercase;
  padding-left: 60px;
  position: relative;
}
.sidebar .information-content h5:before,
.sidebar .travel-package-content h5:before {
  background-color: #F56960;
  content: "";
  height: 2px;
  width: 50px;
  margin-right: 10px;
  position: absolute;
  left: 0;
  top: 50%;
}
.sidebar .information-content h3,
.sidebar .travel-package-content h3 {
  text-transform: uppercase;
}
.sidebar .travel-package-content {
  padding: 50px 30px;
  position: relative;
  z-index: 1;
}
.sidebar .travel-package-content:before {
  background-color: #101F46;
  opacity: 0.85;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.sidebar .travel-package-content h3,
.sidebar .travel-package-content h5,
.sidebar .travel-package-content p {
  color: #ffffff;
}
.sidebar .travel-package-content h5:before {
  background-color: #ffffff;
}
.sidebar .travel-package-content ul {
  text-align: left;
  padding: 0;
  margin: 0;
}
.sidebar .travel-package-content ul li {
  list-style: none;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
}
.sidebar .travel-package-content ul li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.sidebar .travel-package-content ul li i {
  margin-right: 8px;
}
.sidebar .travel-package-content ul li a {
  color: #ffffff;
  line-height: 1.3;
  font-family: "Raleway", sans-serif;
  font-size: 18px;
  font-weight: 700;
}

.form-group {
  margin-bottom: 15px;
}

